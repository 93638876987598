import logosUrl from '../sprites/logos.svg';
import ripplesUrl from '../sprites/ripples.svg';
// the icons sprite is dynamically generated
import iconsUrl from '../../public/build/icons.svg';

const body = document.querySelector("body"),
requestSprite = (e, t, s) => {
    t.open("GET", s, !0);
    let o = () => {
        e.innerHTML += '<iframe src="' + s +
            '" onload="this.before((this.contentDocument.body || this.contentDocument).children[0]);this.remove()"></iframe>'
    };
    t.onload = (() => {
        t.status >= 200 && t.status < 400 ? e.innerHTML += t.responseText : o()
    }), t.onerror = (() => {
        o()
    }), t.send()
},
spriteDiv = document.createElement("div");
spriteDiv.classList.add("uu-svg"), body.appendChild(spriteDiv);
const iconsRequest = new XMLHttpRequest;
requestSprite(spriteDiv, iconsRequest, iconsUrl);
const ripplesRequest = new XMLHttpRequest;
requestSprite(spriteDiv, ripplesRequest, ripplesUrl);
const logosRequest = new XMLHttpRequest;
requestSprite(spriteDiv, logosRequest, logosUrl);

import { default as uuTooltip } from '../droplets/components/tooltip/tooltip';
uuTooltip();
import { default as uuMessage } from '../droplets/components/message/message';
uuMessage();
import { default as uuLoadingButtons } from '../droplets/atoms/button/button';
import { default as uuNavigation } from '../droplets/components/navigation/navigation';
import { autocompleteEvents as uuAutocomplete, autocompleteMatch as uuAutocompleteMatch } from '../droplets/forms/input/autocomplete/autocomplete';
uuAutocomplete();

window['uuAutoComplete'] = uuAutocomplete;
window['uuAutoCompleteMatch'] = uuAutocompleteMatch;
window['uuLoadingButtons'] = uuLoadingButtons;
window['uuNavigation'] = uuNavigation;
