let autoCompleteButtonFocused = false;
const autocompleteEvents = () => {
    const tagTemplate = document.querySelector('uu-field[type=autocomplete] template'),
	    autocompletes = document.querySelectorAll('uu-field[type=autocomplete] input[type=text]');

    autocompletes.forEach(elt => {
        const eltField = elt.closest('uu-field[type=autocomplete]');
        const hiddenSelect = eltField.querySelector('select');
        // let blurTimeout;

        if (hiddenSelect) {
            const tagsContainer = eltField.querySelector('.tags');
            hiddenSelect.addEventListener('change', (e) => {
                const tags = tagsContainer.querySelectorAll('uu-tag');
                tags.forEach(elt => {
                    elt.remove();
                });
                hiddenSelect.querySelectorAll('option[selected]').forEach(elt => {
                    const tag = tagTemplate.content.childNodes[1].cloneNode(true);
                    tag.setAttribute('data-id', elt.value);
                    tag.querySelector('span').innerText = elt.innerText;
                    tag.querySelector('button').setAttribute('aria-label', `Remove ${elt.innerText}`);
                    tagsContainer.appendChild(tag);
                });
            });
            // Force a change on hiddenSelect to populate tags
            hiddenSelect.dispatchEvent(new Event('change'));
        }

        elt.addEventListener('input', (e) => {
            autocompleteMatch(elt);
        });

        elt.addEventListener('keydown', e => {
			switch(e.key) {
            // Hide dropdown on ESC
			case 'Escape':
            case 'Tab':
                autocompleteHide();
				break;

            // Do not submit the form on enter
			case 'Enter':
				e.preventDefault();
				break;

            // Focus the first list item on arrow down
			case 'ArrowDown':
                e.preventDefault();
                autoCompleteButtonFocused = true;
                // clearTimeout(blurTimeout);
				eltField.querySelector('.dropdown button')?.focus();
				break;
			}
		});

        // elt.addEventListener('blur', e => {
        //     if (!autoCompleteButtonFocused) {
        //         blurTimeout = setTimeout(() => {
        //             autocompleteHide();
        //         }, 150);
        //     }
		// });
    });

    document.addEventListener('click', autocompleteHandleClick);
    document.addEventListener('keyup', autocompleteHandleKeyUp);        
},
autocompleteHandleKeyUp = (e) => {
    if (e.key === 'Escape') {
        autocompleteHide();
    }
},
autocompleteHandleClick = (e) => {
    const changeEvent = new Event('change'),
        elt = e.target,
        eltField = elt.closest('uu-field[type=autocomplete]');

    // button and field are only set if the field or button were clicked
    const button = elt.closest('button[data-id]'),
        field = elt.closest('input[type=text]'),
        tagButton = elt.closest('button.close');

    // click happened outside of an autocomplete field, or no button/field was clicked, hide dropdown and abort
    if (!eltField || (!button && !field && !tagButton)) {
        autocompleteHide();
        return;
    }

    if (field) {
        autocompleteMatch(elt);
        return;
    }

    // Only one of those will be defined
    const hiddenSelect = eltField.querySelector('select'),
        hiddenText = eltField.querySelector('input[type=hidden]');

    if (tagButton) {
        const tag = tagButton.closest('uu-tag');
        hiddenSelect.querySelector(`option[value="${tag.dataset.id}"]`).remove();
        hiddenSelect.dispatchEvent(changeEvent);
        return;
    }

    // this is the text field, always set
    const textField = eltField.querySelector('input[type=text]');
    
    e.preventDefault();

    if (hiddenSelect) {
        const optionExists = hiddenSelect.querySelector(`option[value="${button.dataset.id}"]`);
        if (optionExists) {
            optionExists.remove();
        } else {
            const newOption = document.createElement("option");
            newOption.value = button.dataset.id;
            newOption.text = button.querySelector('span:first-child').innerText;
            newOption.setAttribute('selected', true);

            hiddenSelect.appendChild(newOption);                
        }
        autocompleteClear(textField);
        textField.focus();
        hiddenSelect.dispatchEvent(changeEvent);
    } else {
        hiddenText.value = button.dataset.id;
        textField.value = button.querySelector('span:first-child').innerText;
        // Force an input on textField and hiddenText for frameworks like Livewire
        textField.dispatchEvent(new Event('input'));
        hiddenText.dispatchEvent(new Event('input'));
    }
    autocompleteHide();
},
autocompleteMatch = async (elt) => {
    const eltField = elt.closest('uu-field[type=autocomplete]');

    if (elt.value.length < eltField.dataset.min ?? 3) {
        autocompleteHide();
        return;
    }

    // current values of multiSelect
    let currentValues = [];
    const hiddenSelect = eltField.querySelector('select');

    if (hiddenSelect) {
        hiddenSelect.querySelectorAll('option[selected]').forEach(elt => {
            currentValues.push(elt.value);
        });
    }

    const listBox = eltField.querySelector('div[role=listbox]');

    listBox.removeEventListener('keydown', autocompleteListBoxKeyDown);
    listBox.removeEventListener('mouseover', autocompleteListBoxMouseOver);

    const providerName = eltField.dataset.provider;
    let items = [];

    if (providerName) {
        items = await window[providerName](elt.value);

        if (!items.length) {
            listBox.innerHTML = '<span>' + (eltField.dataset.nomatch ? eltField.dataset.nomatch : 'You query did not return any result') + '</span>';
            autocompleteShow(elt);
            return;
        }
    
        let matchingValues = '';
    
        items.forEach(item => {
            matchingValues += `<button tabindex="-1" role="option" data-id="${item.id}"><span>${item.value} ${currentValues.indexOf(item.id) > -1 ? '(remove)' : ''}</span>`;
            if (item.subValue) {
                matchingValues += `<span>${item.subValue}</span>`;
            }
            matchingValues += '</button>';
        });
    
        listBox.innerHTML = matchingValues;
    }

    autocompleteShow(elt);

    eltField.querySelector('.aria-results').innerHTML = `${items.length} matching result${items.length > 1 ? 's' : ''}`;

    listBox.addEventListener('keydown', autocompleteListBoxKeyDown);
    listBox.addEventListener('mouseover', autocompleteListBoxMouseOver);
},
autocompleteClear = (elt) => {
    elt.value = '';
},
autocompleteShow = (elt) => {
    autocompleteHide();
    const eltField = elt.closest('uu-field[type=autocomplete]');
    eltField.querySelector('input[type=text]').setAttribute('aria-expanded', true);
    eltField.querySelector('[data-surrogate]').classList.add('on');
},
autocompleteHide = () => {
    const autoCompletes = document.querySelectorAll('uu-field[type=autocomplete]');
    autoCompletes.forEach(elt => {
        elt.querySelector('input[type=text]').setAttribute('aria-expanded', false);
        elt.querySelector('[data-surrogate]').classList.remove('on');
    });
},
autocompleteListBoxKeyDown = (e) => {
    const eltField = e.target.closest('uu-field[type=autocomplete]'),
        listBox = e.target.closest('div[role=listbox]'),
        focusedButton = listBox.querySelector('button:focus'),
        nextButton = focusedButton ? focusedButton.nextElementSibling : null,
        prevButton = focusedButton ? focusedButton.previousElementSibling : null;

    switch(e.key) {
        case 'Escape':
        case 'Tab':
            autocompleteHide();
            break;

        case 'ArrowDown':
            e.preventDefault();
            if (nextButton) {
                nextButton.focus();
            }
            break;

        case 'ArrowUp':
            e.preventDefault();
            if (prevButton) {
                prevButton.focus();
            } else {
                autoCompleteButtonFocused = false;
                eltField.querySelector('input[type=text]').focus();
            }
            break;
        }
},
autocompleteListBoxMouseOver = (e) => {
    const listBox = e.target.closest('div[role=listbox]');
    const focusedButton = listBox.querySelector('button:focus');
    if (focusedButton) {
        focusedButton.blur();
    }
};

export { autocompleteEvents, autocompleteMatch };
