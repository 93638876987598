const loadingButtons = () => {
	document.addEventListener('click', (event) => {
		const button = event.target.closest('button.uu-button');
        if (button) {
            button.classList.add('loading');
            button.setAttribute('aria-disabled', true);
            button.setAttribute('aria-label', 'Loading');
            if (button.isDisabled) {
                event.preventDefault();
            }
            button.isDisabled = true;
        }
	});
};

export default loadingButtons;
