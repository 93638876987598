const messageInit = () => {
	document.addEventListener('click', (e) => {
		const self = e.target;
		if (self.closest('uu-message .close')) {
			self.closest('uu-floating-message').classList.add('off');
		}
	});
};

export default () => {
	messageInit();
};
