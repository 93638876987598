const initTooltips = () => {
    window.addEventListener('keydown', (e) => {
        if (e.key === 'Escape') {
            closeTooltips();
        }
    });
    window.addEventListener('click', (e) => {
        if (!e.target.closest('uu-tooltip')) {
            closeTooltips();
        }
    });
},
closeTooltips = () => {
    const tooltips = document.querySelectorAll('uu-tooltip button');
    tooltips.forEach((tooltip) => {
        tooltip.classList.remove('hover');
        tooltip.setAttribute('aria-expanded', 'false');
    });
},
resizeTooltips = () => {
	const tooltips = document.querySelectorAll('uu-tooltip > button');
	tooltips.forEach((tooltip) => {
		tooltip.classList.add('js');
		const tip = tooltip.nextElementSibling;
		tip.classList.add('js');

		const toggleTooltip = () => {
            tooltip.classList.toggle('hover');
            tooltip.setAttribute('aria-expanded', tooltip.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
		};

		tooltip.addEventListener('click', () => {
			toggleTooltip();
		});

		tooltip.addEventListener('keydown', (e) => {
			if (e.key === 'Enter' || e.key === 'Spacebar') {
				// pressed enter or space-bar
				e.preventDefault();
				toggleTooltip();
			} else if (e.key === 'Escape') {
				// pressed esc
				closeTooltips();
			}
		});

		// tooltip.addEventListener('blur', () => {
		// 	closeTooltip();
		// });

        // Open the tootlip to measure and position
        toggleTooltip();

		const
			toolTipOffsetLeft = tooltip.offsetLeft + tooltip.offsetWidth / 2,
			toolTipOffsetRight = window.innerWidth - (tooltip.offsetLeft + tooltip.offsetWidth) - tooltip.offsetWidth / 2,
			tipWidth = tip.offsetWidth,
			tipOffsetLeft = toolTipOffsetLeft - tipWidth / 2,
			tipOffsetRight = toolTipOffsetRight - tipWidth / 2;

		tip.style.left = (tipOffsetLeft < 0) ? 0 : `${tipOffsetLeft}px`;

		if (tipOffsetRight < 0) {
			tip.style.right = 0;
			tip.style.left = 'auto';
		}

        // Close after measuring is done
        closeTooltips();
	});
};

export default () => {
	window.onresize = resizeTooltips;
	initTooltips();
	resizeTooltips();
};
